<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="5" xl="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="5" xl="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2" xl="3">
          <v-btn color="success" @click="getConvertDP">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        flat
        dense
        outlined
        background-color="white"
        placeholder="search"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        dense
        show-select
        item-key="id"
        :search="search"
      >
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.po="{ item }">
          {{ item.purchaseOrderNumber.toString() }}
        </template>
        <template v-slot:item.totalAmountAfterTax="{ item }">
          {{ formatPrice(item.totalAmountAfterTax) }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn @click="convert" color="primary" class="mr-2">convert</v-btn>
      <v-btn @click="back" color="error" class="mr-2">cancel</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "convert-dp",
  data() {
    return {
      selected: [],
      items: [],
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
      },
      search: "",
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Inv #",
          value: "invoiceNumber",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Customer",
          value: "customerName",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Date",
          value: "invoiceDate",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Due Date",
          value: "invoiceDueDate",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "PO#",
          value: "po",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 6,
          text: "Total",
          value: "totalAmountAfterTax",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async getConvertDP() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/getListConvert", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async convert() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/convertDP", this.selected)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getConvertDP();
  },
};
</script>

<style></style>
